import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import type { AppProps } from 'next/app';
import Head from "next/head";
import { useRouter } from 'next/router';
import Script from "next/script";
import { lazy, useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { MainAppView } from '../src/components/main/MainAppView';
import { store } from "../src/redux/store";
import theme from "../src/theme/theme";
import { PageTransitionLoading } from '../src/ui/common/PageTransitionLoading';
import AmplitudeHelper from "../src/utils/AmplitudeHelper";
import { GoogleAddToCartParams } from "../src/utils/GoogleAnalyticsHelper";
import { ROUTES_BASE } from '../src/utils/consts';
import '../styles/app.scss';
import Maintenance from './maintenance';
const SpeedInsights = lazy(() => import("../src/utils/VercelHelper"))

if (!Bugsnag.isStarted()) {
    Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG!!, // Should never be null
        plugins: [new BugsnagPluginReact()],
        logger: null
    })
}

if (typeof window !== 'undefined') {
    if(process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') {
        AmplitudeHelper.initAmplitude(process.env.NEXT_PUBLIC_AMPLITUDE_KEY!!);
    }
}
declare global {
    interface Window {
        gtag_report_conversion?(value: string): void
        gtag_report_conversion_validate_order?(value: string): void
        gtag_add_to_cart?(params: GoogleAddToCartParams): void;
        gtag_update_consent?(): void
    }
}
function MyApp({Component, pageProps}: AppProps) {

    const router = useRouter();
    const [isInMaintenanceMode, setIsInMaintenanceMode] = useState<boolean>(false);

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement!!.removeChild(jssStyles);
        }
    }, []);

    useEffect(() => {
        if(!router.isReady) return;

        setIsInMaintenanceMode(router.pathname === ROUTES_BASE.MAINTENANCE)

    }, [router.isReady, router.route]);

    return (
        <>
            {
                typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
                <>
                    <Script async strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />
                    <Script async id="google-analytics" strategy="afterInteractive">
                        {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                                page_path: window.location.pathname,
                                });
                                gtag('config', 'AW-11007527433');
                                
                                gtag('consent', 'default', {
                                  ad_user_data: 'denied',
                                  ad_personalization: 'denied',
                                  ad_storage: 'denied',
                                  analytics_storage: 'denied'
                                });
                                
                                function gtag_report_conversion(url) { 
                                    var callback = function () { 
                                        if (typeof(url) != 'undefined') { 
                                            //window.location = url;  // Don't redirect
                                        } 
                                    }; 
                                    gtag('event', 'conversion', { 'send_to': 'AW-11007527433/tpH3CMKIj4MYEImU5oAp', 'event_callback': callback }); 
                                    return false;
                                 }
                                 
                                  function gtag_report_conversion_validate_order(url) { 
                                    var callback = function () { 
                                        if (typeof(url) != 'undefined') { 
                                            //window.location = url;  // Don't redirect
                                        } 
                                    }; 
                                    gtag('event', 'conversion', { 'send_to': 'AW-11007527433/ckEkCP7YkIsYEImU5oAp', 'transaction_id': '' });
                                    return false;
                                 }
                                
                                  function gtag_add_to_cart(params) {
                                        gtag('event', 'add_to_cart', {
                                            'currency': params.currency,
                                            'value': params.value,
                                            'items': params.items.map(item => ({
                                                'item_id': item.item_id,
                                                'item_name': item.item_name,
                                                'item_category': item.item_category,
                                                'item_category2': item.item_category2,
                                                'price': item.price,
                                                'quantity': item.quantity,
                                            }))
                                        });
                                    }
                                    
                                function gtag_update_consent() {
                                     gtag('consent', 'update', {
                                        ad_user_data: 'granted',
                                        ad_personalization: 'granted',
                                        ad_storage: 'granted',
                                        analytics_storage: 'granted'
                                      });
                                }
                            `}
                    </Script>
                    <Script async id="crisp" strategy="afterInteractive">
                        {`
                        window.$crisp=[["safe", true]];
                        window.CRISP_WEBSITE_ID="c0951531-211c-4575-a1db-36ba4f493eca";
                        (function(){d=document;s=d.createElement("script");
                        s.src="https://client.crisp.chat/l.js";
                        s.async=1;
                        d.getElementsByTagName("head")[0].appendChild(s);})();
                    `}
                    </Script>
                    <Script async
                        id="fb-pixel"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                                    !function(f,b,e,v,n,t,s)
                                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                    n.queue=[];t=b.createElement(e);t.async=!0;
                                    t.src=v;s=b.getElementsByTagName(e)[0];
                                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                                    'https://connect.facebook.net/en_US/fbevents.js');
                                    fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
                                    fbq('track', 'PageView');
                                  `,
                        }}
                    />
                </>
            }

            <StyledEngineProvider injectFirst={false}>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <CookiesProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                <PageTransitionLoading/>
                                {
                                    isInMaintenanceMode ? 
                                        <Maintenance/>
                                        :
                                        <MainAppView
                                            Component={Component}
                                            pageProps={pageProps}
                                            />
                                }
                                <SpeedInsights />
                            </LocalizationProvider>
                        </CookiesProvider>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    )
}

export default MyApp
